<template>
    <!-- 捷胜 -->
    <div id="CustomerDZMotor">
      <!-- CustomerDZMotor头部内容 -->
      <div class="CustomerDZMotorHeader">
        <!-- 头部组件 -->
        <Header />
      </div>
      <!-- CustomerDZMotor中间内容 -->
      <div class="CustomerDZMotorCenter">
        <!-- 客户介绍 -->
        <div class="contentOne">
          <div class="titleOne">客户介绍</div>
          <div class="conInfo">
            <img src="~assets/img/CustomerJS/公司@2x.png" />
            <div class="infoOne">
              &#160;&#160;&#160;&#160;&#160;捷胜海洋专业从事海洋装备系列产品的研发、生产、销售和服务，产业主要涉及海洋渔业装备、海洋科考装备、海事海工装备、及玻璃钢船舶技术与装备，是海洋装备领域的高新技术企业。<br /><br />
              &#160;&#160;&#160;&#160;&#160; 本着“合作共赢”的经营理念，公司与国内海洋产业相关的科研院所建立了良好的产学研合作关系，与国内外知名的海洋装备制造品牌商缔结战略联盟，通过不断承担国家各类科研项目，极大的提升了自主研发能力。
             
            </div>
          </div>
        </div>
        <!-- 项目内容 -->
        <div class="contentTwo">
          <div class="titleTwo">项目内容</div>
          <div class="titleT">
            通过将所有企业内外部信息和资源进行全面的整合和协调，以实现企业解决生产、销售、采购、库存、物流、财务等方面的问题，提高企业运营效率和利润的管理信息系统。通过集成的系统和优化的流程，运用工作流让企业运行变得更加流畅，通过软件为载体，将整个业务流程转换到线上模式。打通管理软件及财务软件的壁垒，实现公司数据一站化。
          </div>
        </div>
    
        </div>
      <!-- 底部内容 -->
      <Footer />
      <!-- 回到顶部 -->
      <el-backtop
        ><img
          src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png"
          alt="icon-回到顶部@2x.png"
      /></el-backtop>
    </div>
  </template>
  <script>
  import Header from "components/Header/dsHeader";
  import Footer from "components/Footer/dsFooter";
  export default {
    name: "CustomerDZMotor",
    data() {
      return {
        imgContents: [
          {
            Imgs: require("assets/img/CustomerNX/a.png"),
            textPro: ""
          }
        ],
       
      };
    },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
    components: {
      Header,
      Footer
    }
  };
  </script>
  <style lang="less" scoped>
  #CustomerDZMotor {
     width: 1583px;
    background-color: #f8f9fb;
    margin: 0 auto;
    // CusCustomerDZMotor头部样式
    .CustomerDZMotorHeader {
      width: 1583px;
      height: 620px;
      background: url("~assets/img/AboutUs/banner@2x.png")
        no-repeat;
      background-size: 1583px 620px;
      background-position: center;
      margin: 0 auto;
    }
    // CustomerDZMotor中间内容样式
    .CustomerDZMotorCenter {
      width: 1220px;
      margin: 80px auto 101px;
      // 客户介绍
      .contentOne {
        .titleOne {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 28px;
          color: #333333;
        }
        .conInfo {
          margin-top: 30px;
          display: flex;
          img {
            width: 560px;
            height: 343px;
          }
          .infoOne {
            width: 590px;
            height: 157px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.85);
            margin: 3px 0 0 40px;
          }
        }
      }
      // 项目价值
      .contentTwo {
        margin-top: 60px;
        .titleTwo {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 28px;
          color: #333333;
        }
        .titleT {
          width: 1200px;
          height: 72px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin-top: 30px;
        }
        .conBot {
          margin-top: 30px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          width: 1220px;
          height: 146px;
  li{
    background-color: #fff;
    width: 215px;
    height: 146px;
    &:nth-child(2){
      .conBotContent{
        width: 113px;
      }
    }
     &:nth-child(3){
      .conBotContent{
        width: 85px;
      }
    }
     &:nth-child(4){
      .conBotContent{
        width: 56px;
      }
    }
     &:nth-child(5){
      .conBotContent{
        width: 155px;
      }
    }
  .conBotTit{
    display: flex;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #1E90FF;
  margin-top: 20px;
    .icons{
  width: 6px;
  height: 20px;
  background: #1E90FF;
  border-radius: 0px 3px 3px 0px;
  margin-right: 30px;
  
  }
  
  
  }
  .conBotContent{
   width: 71px;
  height: 70px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 28px;
  color: #333333;
  margin: 6px 0 0 30px;
  
    }
  }
        }
      }
      // 项目概况
      .contentThree {
        margin-top: 60px;
        .titleThree {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .imgContent {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 30px;
          padding: 0;
          li {
            margin-bottom: 30px;
            background-color: #fff;
           
       
  
            .imgBottom {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
              margin-top: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  </style>
  <style lang="less">
  .el-backtop {
    img {
      width: 30px;
      height: 30px;
    }
  }
  </style>
  